<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" v-if="!$Util.isEmpty(party)">
          <div class="detail_top">
            <div class="party_tag big center">
              <!--'상세페이지에서: big'-->
              <div class="left">
                <div
                  class="pt_tag logo"
                  :class="{ sl: party.acntTransferYn === 'N' }"
                  style="--pt-txt-color: #fff;"
                  :style="{
                    '--pt-bg-color': $ConstCode.getCategoryById(
                      party.categoryId
                    ).color
                  }"
                >
                  {{ categoryNames[party.categoryId].text }}
                </div>
                <div
                  class="pt_tag normal nb blue"
                  v-if="party.acntTransferYn === 'Y'"
                >
                  양도
                </div>
              </div>
            </div>
            <div class="party_ttl">{{ party.title }}</div>
            <div class="party_keyword" v-if="!$Util.isEmpty(searchWords)">
              <span v-for="(item, index) in searchWords" :key="index">{{
                `#${item}`
              }}</span>
            </div>
          </div>
          <div class="lump" v-if="party.acntTransferYn === 'N'">
            <div class="table_type01">
              <dl>
                <dt>현재 판매가</dt>
                <dd>
                  {{ $Util.formatNumber2(party.partyAmt, { unit: '원' })
                  }}<br />
                  <span class="daily">{{
                    `(${$Util.formatNumber2(party.dailyPrice, { unit: '원' })})`
                  }}</span>
                </dd>
              </dl>
              <dl>
                <dt>파티 현황</dt>
                <dd>
                  <span>
                    <span class="color">
                      {{ party.totalMemberCnt }}
                    </span>
                    {{ `/${party.recruitNum}` }}
                    <button
                      style="margin-left: 0.8em; display: inline; width: 96px; height: 32px; color: #fff; background: var(--unnamed-color-00adef) 0% 0% no-repeat padding-box; border: 1px solid var(--unnamed-color-00adef); background: #00ADEF 0% 0% no-repeat padding-box; border: 1px solid #00ADEF; border-radius: 5px; opacity: 1;"
                      class="btn"
                      @click="fnClose"
                      v-if="party.partyStatus === 'N'"
                    >
                      모집 마감
                    </button>
                    <span v-if="party.partyStatus === 'Y'"
                      ><b style="margin-left: 0.8em; color: #003471;"
                        >마감</b
                      ></span
                    >
                  </span>
                </dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>정산 기간</dt>
                <dd class="space_between" v-if="party.unlimitedCheckYn === 'N'">
                  <span>{{
                    `${$dayjs(party.regDt)
                      .add(2, 'd')
                      .format('YYYY.MM.DD')} ~ ${$dayjs(party.useDt)
                      .add(1, 'd')
                      .format('YYYY.MM.DD')}`
                  }}</span>
                  <span
                    ><span class="color">{{ party.status }}</span
                    >{{
                      $Util.isNumeric(party.status) &&
                      party.autoExtension === 'Y'
                        ? '일 후 자동연장'
                        : $Util.isNumeric(party.status) &&
                          party.autoExtension === 'N'
                        ? '일 남음'
                        : ''
                    }}</span
                  >
                </dd>
                <dd v-else class="space_between">표시 없음</dd>
              </dl>
              <dl>
                <dt>이용 기간</dt>
                <dd class="space_between" v-if="party.unlimitedCheckYn === 'N'">
                  <span>{{
                    `${$dayjs(party.regDt).format('YYYY.MM.DD')} ~ ${$dayjs(
                      party.useDt
                    )
                      .subtract(1, 'd')
                      .format('YYYY.MM.DD')}`
                  }}</span>
                  <span
                    ><span class="color">{{ party.status }}</span
                    >{{
                      $Util.isNumeric(party.status) &&
                      party.autoExtension === 'Y'
                        ? '일 후 자동연장'
                        : $Util.isNumeric(party.status) &&
                          party.autoExtension === 'N'
                        ? '일 남음'
                        : ''
                    }}</span
                  >
                </dd>
                <dd v-else class="space_between">표시 없음</dd>
              </dl>
            </div>
          </div>
          <div class="lump bo_zero" v-else-if="party.acntTransferYn === 'Y'">
            <div class="table_type01">
              <dl>
                <dt>사용일</dt>
                <dd>
                  {{
                    party.unlimitedCheckYn === 'N'
                      ? `${party.status}일`
                      : '표시 없음'
                  }}
                </dd>
              </dl>
              <dl>
                <dt>판매가</dt>
                <dd>
                  {{ $Util.formatNumber2(party.dailyPrice, { unit: '원' }) }}
                </dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>이용 기간</dt>
                <dd class="space_between" v-if="party.unlimitedCheckYn === 'N'">
                  <span>{{
                    `${$dayjs(party.regDt).format('YYYY.MM.DD')} ~ ${$dayjs(
                      party.useDt
                    )
                      .subtract(1, 'd')
                      .format('YYYY.MM.DD')}`
                  }}</span>
                  <span
                    ><span class="color">{{ party.status }}</span
                    >{{
                      $Util.isNumeric(party.status) &&
                      party.autoExtension === 'Y'
                        ? '일 후 자동연장'
                        : $Util.isNumeric(party.status) &&
                          party.autoExtension === 'N'
                        ? '일 남음'
                        : ''
                    }}
                  </span>
                </dd>
                <dd v-else class="space_between">표시 없음</dd>
              </dl>
              <dl
                v-if="
                  !$Util.isEmpty(acntTransferMember) &&
                    party.acntTransferYn === 'Y'
                "
              >
                <dt>결제자 정보</dt>
                <dd class="buyer_info">
                  <span class="name">{{ acntTransferMember.nick }}</span>
                  <!--                  <span>{{ acntTransferMember.nick }}</span>-->
                  <div
                    class="sv_tag"
                    :class="[
                      {
                        kakao:
                          acntTransferMember.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.KAKAO.enName
                      },
                      {
                        naver:
                          acntTransferMember.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.NAVER.enName
                      },
                      {
                        google:
                          acntTransferMember.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.GOOGLE.enName
                      },
                      {
                        apple:
                          acntTransferMember.loginType ===
                          $ConstCode.SNS_LOGIN_TYPE.APPLE.enName
                      }
                    ]"
                    v-if="acntTransferMember.loginType !== 'wizzney'"
                  >
                    {{ acntTransferMember.loginType }}
                  </div>
                </dd>
              </dl>
              <!--              <dl-->
              <!--                v-if="-->
              <!--                  !$Util.isEmpty(acntTransferMember) &&-->
              <!--                    party.acntTransferYn === 'Y'-->
              <!--                "-->
              <!--              >-->
              <!--                <dt>총 결제액</dt>-->
              <!--                <dd class="space_between">-->
              <!--                  <div>-->
              <!--                    {{-->
              <!--                      `${$Util.formatNumber2(-->
              <!--                        Number(acntTransferMember.partyAmt) +-->
              <!--                          Number(acntTransferMember.point),-->
              <!--                        { unit: '' }-->
              <!--                      )}${-->
              <!--                        !$Util.isEmpty(acntTransferMember.point) &&-->
              <!--                        Number(acntTransferMember.point) > 0-->
              <!--                          ? ' - '-->
              <!--                          : ''-->
              <!--                      }`-->
              <!--                    }}-->
              <!--                    <span-->
              <!--                      class="color"-->
              <!--                      v-if="-->
              <!--                        !$Util.isEmpty(acntTransferMember.point) &&-->
              <!--                          Number(acntTransferMember.point) > 0-->
              <!--                      "-->
              <!--                      >{{-->
              <!--                        $Util.formatNumber2(acntTransferMember.point, {-->
              <!--                          unit: 'P'-->
              <!--                        })-->
              <!--                      }}</span-->
              <!--                    >-->
              <!--                  </div>-->
              <!--                  <div class="total">-->
              <!--                    <span class="sec_color">{{-->
              <!--                      $Util.formatNumber2(acntTransferMember.partyAmt, {-->
              <!--                        unit: ''-->
              <!--                      })-->
              <!--                    }}</span>-->
              <!--                    원-->
              <!--                  </div>-->
              <!--                </dd>-->
              <!--              </dl>-->
              <dl
                v-if="
                  !$Util.isEmpty(acntTransferMember) &&
                    party.acntTransferYn === 'Y'
                "
              >
                <dt>결제 방법</dt>
                <dd>{{ acntTransferMember.paymentType }}</dd>
              </dl>
              <dl
                v-if="
                  !$Util.isEmpty(acntTransferMember) &&
                    party.acntTransferYn === 'Y'
                "
              >
                <dt>결제일</dt>
                <dd>
                  {{
                    $dayjs(acntTransferMember.paymentDt).format('YYYY.MM.DD')
                  }}
                </dd>
              </dl>
            </div>
          </div>
          <div class="lump" v-if="party.acntTransferYn === 'N'">
            <div class="table_type02">
              <dl>
                <dt>자동연장 설정</dt>
                <dd>
                  {{ party.autoExtension === 'Y' ? '자동연장' : '-' }}
                </dd>
              </dl>
            </div>
          </div>
          <div class="lump bo_zero">
            <div class="table_type02">
              <dl>
                <dt>
                  파티 소개 글 및 주의사항
                  <span class="table_date">{{
                    $dayjs(party.regDt).format('YYYY.MM.DD')
                  }}</span>
                </dt>
                <dd class="editor">
                  <span>{{ party.precautions }}</span>
                </dd>
              </dl>
            </div>
          </div>
          <div class="lump bo_zero" v-if="party.shareYn === 'N'">
            <div class="table_type02">
              <dl>
                <dt>
                  공유 계정
                  <span class="table_date">{{
                    $dayjs(party.shareRegDt).format('YYYY.MM.DD')
                  }}</span>
                </dt>
                <dd>
                  <div class="share_info">
                    <dl>
                      <dt>ID</dt>
                      <dd>{{ party.shareId }}</dd>
                    </dl>
                    <dl>
                      <dt>PW</dt>
                      <dd>{{ party.sharePw }}</dd>
                    </dl>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <!--          <div class="lump" v-if="!$Util.isEmpty(party.precautionRegDt)">-->
          <!--            <div class="table_type02">-->
          <!--              <dl>-->
          <!--                <dt>-->
          <!--                  주의사항-->
          <!--                  <span class="table_date">{{-->
          <!--                    $dayjs(party.precautionRegDt).format('YYYY.MM.DD')-->
          <!--                  }}</span>-->
          <!--                </dt>-->
          <!--                <dd class="editor">-->
          <!--                  <div v-html="party.precautions"></div>-->
          <!--                </dd>-->
          <!--              </dl>-->
          <!--            </div>-->
          <!--          </div>-->

          <div
            class="lump bo_zero"
            v-if="party.acntTransferYn === 'Y' && approval"
          >
            <div class="table_type02">
              <dl>
                <dt>
                  승인 완료
                  <span
                    class="table_date"
                    :class="{ new: Number(approvalBadge) === 1 }"
                    >{{ $dayjs(approvalDt).format('YYYY.MM.DD') }}</span
                  >
                </dt>
                <dd class="editor">
                  <div>결제가 (자동) 승인되었습니다.</div>
                </dd>
              </dl>
            </div>
          </div>

          <div
            class="status"
            v-if="
              party.acntTransferYn === 'Y' &&
                !$Util.isEmpty(acntTransferConflict)
            "
          >
            <div
              class="inner"
              v-for="(item, index) in acntTransferConflict"
              :key="index"
            >
              <div>
                <div>
                  <dl class="status_sec">
                    <dt class="status_ttl">
                      취소/환불 신청
                      <span class="status_date">{{
                        $dayjs(item.regDt).format('YYYY.MM.DD')
                      }}</span>
                    </dt>
                    <dd class="status_main">
                      <div class="status_sec_ttl">
                        <div class="reason">
                          <span class="sec_color">{{
                            Number(item.option) === 0
                              ? '단순 변심'
                              : '파티장 과실'
                          }}</span>
                        </div>
                      </div>
                      <div class="editor">
                        <div v-html="item.msg"></div>
                      </div>
                    </dd>
                    <dd class="status_sub">
                      <div>
                        · 파티장은 취소/환불 신청일로부터 3일 이내에 취소/환불
                        또는 반려 처리해야 합니다. 3일 이후는 자동 취소/환불
                        승인됩니다.
                      </div>
                    </dd>
                  </dl>
                  <dl class="status_sec" v-if="item.status === '1'">
                    <dt class="status_ttl">
                      취소/환불 안내
                      <span
                        class="status_date"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                        >{{
                          $dayjs(item.replyRegDt).format('YYYY.MM.DD')
                        }}</span
                      >
                    </dt>
                    <dd class="status_main">
                      <div class="status_sec_ttl">
                        <div class="reason">
                          <span class="color">승인 완료</span>
                        </div>
                      </div>
                      <div class="editor">
                        <div>
                          파티장 (자동) 승인에 따라 결제내역이 (부분)
                          취소되었습니다.
                        </div>
                      </div>
                    </dd>
                  </dl>
                  <dl class="status_sec" v-if="item.status === '2'">
                    <dt class="status_ttl">
                      취소/환불 안내
                      <span
                        class="status_date"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                        >{{
                          $dayjs(item.replyRegDt).format('YYYY.MM.DD')
                        }}</span
                      >
                    </dt>
                    <dd class="status_main">
                      <div class="status_sec_ttl">
                        <div class="reason">
                          <span class="error">신청 반려</span>
                        </div>
                        <button
                          class="btn_revise"
                          @click="
                            fnRefundReject($ConstCode.BOOLEAN_VALUE.TRUE, item)
                          "
                        ></button>
                      </div>
                      <div class="editor">
                        <div v-html="item.reply"></div>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
              <div class="lump bo_zero" v-if="item.status === '3'">
                <div class="table_type02">
                  <dl>
                    <dt>
                      분쟁 취소 안내
                      <span
                        class="table_date"
                        :class="{ new: Number(item.hasBadge) === 1 }"
                        >{{ $dayjs(item.regDt).format('YYYY.MM.DD') }}</span
                      >
                    </dt>
                    <dd class="editor">
                      <div>
                        <!--붙여쓰기-->취소 환불 신청이 취소되었습니다.<!--붙여쓰기-->
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div class="take_part" v-if="!$Util.isEmpty(member)">
            <div class="inner">
              <div class="take_part_ttl">
                <span>{{
                  party.acntTransferYn === 'N' ? '참여 파티원' : '구매자'
                }}</span>
                <span v-if="party.acntTransferYn === 'N'">{{
                  `${party.totalMemberCnt}/${party.recruitNum}`
                }}</span>
              </div>
              <div class="take_part_list">
                <!--1-->
                <div
                  class="take_part_sec"
                  v-for="(item, index) in member"
                  :key="index"
                >
                  <button class="take_part_btn" @click="fnIsShowMember(item)">
                    <span
                      class="buyer_info"
                      :class="{ new: Number(item.hasBadge) === 1 }"
                    >
                      <span class="take_percent">
                        <span
                          v-if="
                            $Util.isNumeric(item.state) || item.state === '진행'
                          "
                          ><!-- 일시정지: error or 종료: grey -->
                          {{
                            $Util.formatNumber2(item.progressRate, {
                              unit: ' %'
                            })
                          }}</span
                        >
                        <span
                          class="error"
                          v-else-if="item.state === '일시정지'"
                          ><!-- 일시정지: error or 종료: grey -->
                          일시정지</span
                        >
                        <span
                          class="error"
                          v-else-if="item.state === '취소/환불'"
                          ><!-- 일시정지: error or 종료: grey -->
                          취소/환불</span
                        >
                        <span class="grey" v-else-if="item.state === '종료'"
                          ><!-- 일시정지: error or 종료: grey -->
                          종료</span
                        >
                      </span>
                      <!--                      <span class="name">{{ item.nick }}</span>-->
                      <span class="pt_tag ">{{ item.nick }}</span>
                      <!--                      <div-->
                      <!--                        class="sv_tag"-->
                      <!--                        :class="[-->
                      <!--                          {-->
                      <!--                            kakao:-->
                      <!--                              item.loginType ===-->
                      <!--                              $ConstCode.SNS_LOGIN_TYPE.KAKAO.enName-->
                      <!--                          },-->
                      <!--                          {-->
                      <!--                            naver:-->
                      <!--                              item.loginType ===-->
                      <!--                              $ConstCode.SNS_LOGIN_TYPE.NAVER.enName-->
                      <!--                          },-->
                      <!--                          {-->
                      <!--                            google:-->
                      <!--                              item.loginType ===-->
                      <!--                              $ConstCode.SNS_LOGIN_TYPE.GOOGLE.enName-->
                      <!--                          }-->
                      <!--                        ]"-->
                      <!--                        v-if="item.loginType !== 'wizzney'"-->
                      <!--                      >-->
                      <!--                        {{ item.loginType }}-->
                      <!--                      </div>-->
                    </span>
                    <span>{{
                      $Util.formatNumber2(item.cumulativeAmt, {
                        unit: ' 캐시'
                      })
                    }}</span>
                  </button>
                  <div
                    class="buyer_info_detail"
                    :class="{ on: !$Util.isEmpty(item.isShow) && item.isShow }"
                  >
                    <div class="inner">
                      <dl>
                        <dt>업데이트일</dt>
                        <dd>
                          {{ $dayjs(item.updateDt).format('YYYY.MM.DD') }}
                        </dd>
                      </dl>
                      <dl>
                        <dt>결제 방법</dt>
                        <dd>{{ item.card }}</dd>
                      </dl>
                      <dl>
                        <dt>시작일</dt>
                        <dd>
                          {{ $dayjs(item.regDt).format('YYYY.MM.DD') }} ({{
                            item.regPeriod
                          }}일)
                        </dd>
                      </dl>
                      <dl>
                        <dt>총 예상 캐시</dt>
                        <dd>
                          {{
                            $Util.formatNumber2(item.totalAmt, {
                              unit: ' 캐시'
                            })
                          }}
                          (일
                          {{
                            $Util.formatNumber2(item.dailyPrice, {
                              unit: '원'
                            })
                          }})
                        </dd>
                      </dl>
                      <div class="con_btn">
                        <div class="btn_wrap">
                          <button
                            class="btn bo"
                            @click="fnPersonalWizzTalk(index)"
                          >
                            위즈톡<span class="wiz_icon" />
                          </button>
                          <button
                            class="btn bo"
                            style="margin-left: 1.6rem"
                            @click="fnDetailMember(item)"
                          >
                            자세히 보기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--//1-->
              </div>
            </div>
          </div>

          <div
            class="empty_wrap"
            v-else-if="$Util.isEmpty(member) && party.acntTransferYn === 'N'"
          >
            <div class="empty">
              <div>
                아직 참여한 파티원이 없습니다.
              </div>
            </div>
          </div>

          <div class="con_btn">
            <div class="btn_wrap" v-if="!$Util.isEmpty(runningConflict)">
              <button class="btn bo" @click="fnRefundReject">신청 반려</button>
              <button class="btn bo" @click="fnRefund">취소/환불</button>
            </div>
            <div
              class="btn_wrap"
              v-if="
                buttonNameList.includes('fullNotice') &&
                  buttonNameList.length > 2
              "
            >
              <button class="btn bo" @click="fnModalSendAllWizzTalk">
                전체 공지<span class="wiz_icon" />
              </button>
            </div>
            <div class="btn_wrap">
              <button
                class="btn bo"
                v-if="buttonNameList.includes('wizztalk')"
                @click="fnWizzTalk"
              >
                위즈톡<span class="wiz_icon"></span>
              </button>
              <button
                class="btn bo"
                @click="fnModalSendAllWizzTalk"
                v-if="
                  buttonNameList.includes('fullNotice') &&
                    buttonNameList.length <= 2
                "
              >
                전체 공지<span class="wiz_icon" />
              </button>
              <button
                class="btn bo"
                @click="fnDelete"
                v-if="buttonNameList.includes('partyDelete')"
              >
                파티 삭제
              </button>
              <button
                class="btn"
                @click="fnEdit"
                v-if="buttonNameList.includes('partyEdit')"
              >
                파티 내용 수정
              </button>
              <button
                class="btn"
                @click="fnRegenerate"
                v-else-if="buttonNameList.includes('partyRegenerate')"
              >
                파티 재등록
              </button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>유의사항</dt>
                <dd>
                  <div>
                    * 위즈니는 사용자간의 컨텐츠 거래를 지원해드리고 있으며,
                    거래 주체가 아닌 플랫폼 공간을 제공합니다.
                  </div>
                  <div>
                    * 파티장이 등록한 파티 정보 및 거래에 대한 책임은 파티장의
                    몫이며, 이에 따라서 파티장이 진행하는 서비스에 대해서
                    위즈니는 어떠한 책임과 의무를 지지 않습니다.
                  </div>
                  <div>
                    * 파티장 연락두절 및 이용 불가능한 상태 방치 등에 의한
                    환불인 경우 위즈니에서 남은 기간에 대한 환불을 보장합니다.
                    (단 참여 후 1일이 경과되지 않은 상태에서 파티장 과실에 의한
                    사용 불가가 확인 된 경우 100% 금액 환불)
                  </div>
                  <div class="sec_color" v-if="party.acntTransferYn === 'Y'">
                    * 양도 거래시에 발생되는 분쟁은 당사자간에 해결이 필요하며,
                    위즈니는 책임을 지지 않습니다. 반드시 계정 양도에 적합한
                    상품인지 확인하고 거래해주세요.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-created-party-view-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
