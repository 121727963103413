import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    partyNo: route.query.partyNo,
    party: {},
    member: [],
    searchWords: computed(() => {
      if (proxy.$Util.isEmpty(state.party.searchWord)) return []
      return state.party.searchWord.split(',')
    }),
    acntTransferMember: {},
    acntTransferConflict: [],
    acntTransferChatroomId: '',
    approval: false,
    approvalBadge: '',
    approvalDt: '',
    runningConflict: computed(() => {
      if (proxy.$Util.isEmpty(state.acntTransferConflict)) return {}
      return proxy.$_.find(state.acntTransferConflict, { status: '0' })
    }),
    categoryNames: proxy.$_.keyBy(proxy.$ConstCode.CATEGORY2_VALUE, 'id'),
    isAllClosed: computed(() => {
      if (proxy.$Util.isEmpty(state.member)) return false
      const remains = state.member.filter(item => Number(item.status) < 2)
      if (proxy.$Util.isEmpty(remains)) return true
      return false
    }),
    buttonNameList: []
  })
  const fnDetail = async () => {
    const params = {}
    params.partyNo = state.partyNo
    const res = await proxy.$MyPageSvc.postPartyDetail(params)
    if (res.resultCode === '0000') {
      state.party = res.party
      state.member = res.member
      if (state.party.acntTransferYn === 'Y') {
        state.approval = res.approval
        state.approvalBadge = res.approvalBadge
        state.approvalDt = res.approvalDt
        const member = proxy.$_.head(state.member)
        if (!proxy.$Util.isEmpty(member)) {
          await fnMemberDetail(member)
        }
      }
      fnGetAppearButtonNameList()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnMemberDetail = async member => {
    const params = {}
    params.partyNo = state.partyNo
    params.memberSeq = member.memberSeq
    const res = await proxy.$MyPageSvc.postPartyMemberDetail(params)
    if (res.resultCode === '0000') {
      state.acntTransferMember = res.member
      state.acntTransferConflict = res.conflict
      state.acntTransferChatroomId = res.chatroomId
    } else {
      alert(res.resultMsg)
    }
  }

  const fnRegenerate = async () => {
    if (!confirm('재등록 하시겠습니까?')) return
    const params = {}
    params.partyNo = state.party.partyNo
    await router.push({
      name: 'my-page-created-party-edit-index',
      params: { regenerate: true },
      query: { partyNo: params.partyNo }
    })
    return
  }

  const fnDelete = async () => {
    if (!confirm('삭제하시겠습니까?')) return
    const params = {}
    params.partyNo = state.partyNo
    const res = await proxy.$MyPageSvc.postPartyDelete(params)
    if (res.resultCode === '0000') {
      alert('파티가 삭제되었습니다.')
      await router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }
  const fnEdit = async () => {
    await router.push({
      path: '/my-page/created-party/edit',
      query: { partyNo: state.partyNo }
    })
  }
  const fnClose = async () => {
    if (
      !confirm(
        '더 이상 파티 모집을 하지 않겠습니까?\n마감되면 현재 참여 인원에게만 서비스를 제공합니다.\n인원을 마감하면 본 파티에서 추가 모집은 불가합니다.'
      )
    )
      return
    const params = {}
    params.partyNo = state.partyNo
    const res = await proxy.$MyPageSvc.postCreatePartyClose(params)
    if (res.resultCode === '0000') {
      alert('파티모집이 마감되었습니다.')
      await router.go(0)
    } else {
      alert(res.resultMsg)
    }
  }

  const fnIsShowMember = member => {
    if (proxy.$Util.isEmpty(member.isShow)) {
      member.isShow = true
    } else {
      member.isShow = !member.isShow
    }
  }

  const fnDetailMember = async item => {
    const query = {}
    query.partyNo = state.partyNo
    query.memberSeq = item.memberSeq
    query.categoryId = state.party.categoryId
    await router.push({ path: '/my-page/created-party/member', query: query })
  }

  const fnWizzTalk = async () => {
    await router.push({
      path: '/wizz-talk',
      query: {
        partyNo: state.partyNo,
        chatroomId: state.acntTransferChatroomId
      }
    })
  }

  const fnPersonalWizzTalk = async index => {
    await router.push({
      name: 'wizz-talk-index',
      query: {
        partyNo: state.partyNo,
        chatroomId: state.member[index].chatroomId
      }
    })
  }

  const fnModalSendAllWizzTalk = async () => {
    const membersChatroomId = state.member.reduce(
      (pv, cv) => [...pv, cv.chatroomId],
      []
    )
    const partyNo = state.partyNo

    const payload = {
      component: proxy.$modalAlertNames.CREATED_PARTY_NOTICE_WIZZTALK,
      data: {
        membersChatroomId,
        partyNo
      },
      callback: () => {}
    }

    await fnModalAlert(payload)
  }

  const fnRefundReject = async (isEdit = 1, item = {}) => {
    console.log(isEdit, item)
    const payload = {
      component: proxy.$modalAlertNames.PARTY_REFUND_REJECT,
      data: {
        seq:
          proxy.$ConstCode.BOOLEAN_VALUE.TRUE === isEdit
            ? item.seq
            : state.runningConflict.seq,
        reply: proxy.$ConstCode.BOOLEAN_VALUE.TRUE === isEdit ? item.reply : '',
        isEdit: isEdit
      },
      callback: async () => {
        await fnDetail()
      }
    }
    await fnModalAlert(payload)
  }

  const fnRefund = async () => {
    if (
      !confirm(
        '취소 환불을 승인하시겠습니까? 승인 시 파티원의 신청일부터 잔여기간까지 비용이 환불됩니다.'
      )
    )
      return

    const params = {}
    params.seq = state.runningConflict.seq
    const res = await proxy.$PartySvc.postPartyCancelRefundAccept(params)
    if (res.resultCode === '0000') {
      alert('취소 환불되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnGetAppearButtonNameList = () => {
    if (
      state.party.acntTransferYn === 'Y' &&
      state.acntTransferMember.wizztalkYn === 'Y' &&
      state.party.status !== '종료'
    )
      state.buttonNameList.push('wizztalk')

    if (
      (state.party.acntTransferYn === 'N' &&
        proxy.$Util.isEmpty(state.member)) ||
      (state.party.acntTransferYn === 'Y' && state.approval) ||
      state.party.status === '종료' ||
      state.isAllClosed
    )
      state.buttonNameList.push('partyDelete')

    if (
      (state.party.acntTransferYn === 'N' &&
        proxy.$Util.isNumeric(state.party.status)) ||
      (state.party.acntTransferYn === 'Y' && !state.approval)
    ) {
      state.buttonNameList.push('partyEdit')
    } else {
      state.buttonNameList.push('partyRegenerate')
    }

    if (
      state.party.acntTransferYn === 'N' &&
      !proxy.$Util.isEmpty(state.member)
    )
      state.buttonNameList.push('fullNotice')
  }

  const init = async () => {
    await fnDetail()
    const lumps = document.querySelectorAll('.lump')
    proxy.$_.last(lumps).classList.add('bo_zero')
  }
  init()
  return {
    ...toRefs(state),
    fnRegenerate,
    fnDelete,
    fnEdit,
    fnClose,
    fnIsShowMember,
    fnDetailMember,
    fnWizzTalk,
    fnRefundReject,
    fnRefund,
    fnPersonalWizzTalk,
    fnModalSendAllWizzTalk
  }
}
